import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Redirect, Route, Switch } from "react-router";

import { createBrowserHistory } from 'history';

import Logo from './images/logo.png';
import { Home } from './views/Home'
import { Modal } from './components/modal'
import { Profile  } from './views/Profile';
import { AddLift } from './views/AddLift'



export const history = createBrowserHistory();

class App extends Component {

  state = {
    displayAddLift: false
  }

  toggleAddLift = () => {
    // console.log('click')
    this.setState({ displayAddLift: !this.state.displayAddLift })
  }

  render() {
    return (
        <div className="flex flex-col-reverse md:flex-col h-screen">
          {/* Desktop- Logo */}
          <div className="invisible md:visible h-0 md:h-20 xs:overflow-auto md:overflow-visible bg-black text-white flex items-center xs:mb-12 mb">
            <div className="w-full md:w-5/6 order-2 md:order-1 text-center md:text-left p-4">
              <a href='/'>
                <img
                  alt="Strongman Lifts"
                  src={Logo}
                  className="ml-8 mr-auto md:ml-0"
                  style={{ height:"30px" }} 
                />
              </a>
            </div>

            {/* Spacer */}
            <div className="w-18 w-12 order-1 md:order-2 md:ml-auto" />

            {/* Desktop - Add Lift button */}
            <div className="flex order-3 text-center">
              <div className="m-1 md:mx-4 w-12 bg-red-500">
                <button onClick={() => this.toggleAddLift()}>
                  Add Lift
                </button>
              </div>
            </div>
          </div>

          {/* Mobile - Logo */}
          <div className="visible md:invisible bg-black order-4 w-full py-2">
            <a href='/'>
              <img
                alt="Strongman Lifts"
                src={Logo}
                className="mx-auto"
                style={{ height:"30px" }}
              />
            </a>
          </div>

          {/* Mobile - Add Lift button */}
          <div
            className="fixed w-full h-12 bottom-0 bg-red-500 text-center md:invisible md:mx-4 text-white p-2"
            onClick={() => this.toggleAddLift()}
          >
            Add Lift
          </div>


          <Router history={history}>
            <Switch>
              <Route path="/athlete/:uid" component={Profile}/>
              <Route path="/" component={Home}/>
              <Route path="**">
                  <Redirect to="/" />
              </Route>
            </Switch>
          </Router>

          {/* Add Lift Modal */}
          <Modal 
            display={this.state.displayAddLift}
            onClose={this.toggleAddLift}
            child={
              <AddLift
                closeModal={this.toggleAddLift}
              />
            }
          />
        </div>

    );
  }
}


export default App;
