import React, { Component } from 'react';
import { get } from '../services/api';


// const Stat = ({ label, value }) => (
//   <div className="w-1/3 pl-2">
//     <div className='text-sm text-gray-600'>
//       {label}
//     </div>
//     <div>
//       {value}
//     </div>
//   </div>
// )

export class Profile extends Component {

    state = {
      isMobile: false,
      loading: true,
      unfilteredLifts: [],
      weight_classFilter: null,
      genderFilter: null,
      lifts: [],
      name: '',
      sortBy: 'total'
    }
  
    componentDidMount = () => {
      const { uid } = this.props.match.params;
      const screenWidth = window.innerWidth;
  
      if (screenWidth < 500){
        this.setState({ 
          isMobile: true
        })
      }

      // fetch lifts on page load
      this.getLifts(uid);
      // refresh data whenever the user adds a lift
      document.addEventListener("lift", function(e) {
        this.getLifts(uid);
      });
    }

    getLifts = (uid) => {
      get(`api/athlete/${uid}`).then((data) => {
        this.setState({ 
            lifts: data.lifts,
            name: data.name,
            loading: false
        })
      })
    }

    render() {
      const { 
        isMobile,
        loading,
        lifts,
        name
      } = this.state;

      return (
              <div
                className="h-full"
                style={{ backgroundColor: lifts.length % 2 === 0 ? '#F9FAFB' : '#F3F4F6' }}
              >
                <div
                  className='w-full bg-white text-center p-2 font-bold font-lg' 
                  style={{ minHeight: '40px' }}
                >
                  {name}
                </div>
                
                {/* Different views based on screen size */}
                {isMobile 
                ?
                <>
                    {/* <div className="flex bg-white">
                      <Stat
                        label={'Nationality'}
                        value={'Irish'}
                      />
                      <Stat
                        label={'Best Log'}
                        value={'Irish'}
                      />
                      <Stat
                        label={'Best Deadlift'}
                        value={'Irish'}
                      />
                    </div> */}
                    <div className="flex py-2 font-semibold" style={{ backgroundColor: "#fff"}}>
                        <div className="w-24 pl-2">#</div>
                        <div className="w-96">Competition</div>
                        <div className="w-48">Class</div>
                        <div className="w-48">Log</div>
                        {/* <div className="w-48">Gender</div> */}
                        <div className="w-48">DL</div>
                    </div>
                    {lifts.map((lift, idx) => (
                    <div
                        className="py-2 flex w-full"
                        style={{ backgroundColor: idx % 2 !== 0 ? '#F9FAFB' : '#F3F4F6' }}
                    >
                        <div className="w-24 pl-2">{idx+1}</div>
                        <div className="w-96">{lift.competition}</div>
                        <div className="w-48">{lift.weight_class}</div>
                        <div className="w-48">{Math.round(lift.log, 2)}</div>
                        {/* <div className="w-48">{lift.gender}</div> */}
                        <div className="w-48">{Math.round(lift.deadlift, 2)}</div>
                    </div>
                ))}
                </>
                :
                <>
                    <div className="flex py-2 font-semibold" style={{ backgroundColor: "#fff"}}>
                      <div className="w-24 pl-2">#</div>
                      {/* <div className="w-96">Name</div> */}
                      <div className="w-96">Competition</div>
                      <div className="w-48">Class</div>
                      {/* <div className="w-48">Nationality</div> */}
                      <div className="w-48">Date</div>
                      <div className="w-48">Log</div>
                      <div className="w-48">Deadlift</div>
                      <div className="w-48">Total</div>
                      {/* <div className="w-48">Gender</div> */}
                      {/* <div className="w-48">Age</div> */}
                      {/* <div className="w-24">Verified</div>  */}
                    </div>
                    {lifts.map(({ 
                        uid,
                        competition,
                        name,
                        weight_class,
                        nationality,
                        competition_date,
                        log,
                        deadlift,
                        total,
                        gender,
                        age
                    }, idx) => (
                    <div
                        className="py-2 flex w-full"
                        style={{ backgroundColor: idx % 2 !== 0 ? '#F9FAFB' : '#F3F4F6' }}
                        key={idx}
                    >
                        <div className="w-24 pl-2">{idx + 1}</div>
                        {/* <div className="w-96">
                          <Link to={"/" + uid}>
                            {name}
                          </Link>
                        </div> */}
                        <div className="w-96">{competition}</div>
                        <div className="w-48">{weight_class}</div>
                        {/* <div className="w-48">{nationality}</div> */}
                        <div className="w-48">{competition_date.slice(0,10)}</div>
                        <div className="w-48">{Math.round(log, 2)}</div>
                        <div className="w-48">{Math.round(deadlift, 2)}</div>
                        <div className="w-48">{Math.round(total, 2)}</div>    
                        {/* <div className="w-48">{gender}</div> */}
                        {/* <div className="w-48">{age}</div> */}
                    </div>
                    ))}
                </>
                }
                <>
                    {loading && 
                        <div className='w-full text-center'>
                            Loading...
                        </div>
                    }
                </>
              </div>
              )
    }
  }
  