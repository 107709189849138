import React from 'react';
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { Link } from "react-router-dom";

export default function AthleteLink({name, uid}) {
  const flagEnabled = useFeatureFlagEnabled('strongmanlifts-athlete-profile')
  if (flagEnabled) {
    return (
      <Link to={"/athlete/" + uid}>{name}</Link>
    )
  }
  return (
    <span>{name}</span>
  )
}
