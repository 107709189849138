import React, { Component } from 'react';
import { get } from '../services/api';
import { Select } from '../components/layout/input'
import { GenderOptions, LiftOptions, NationalityFilterOptions, WeightClassOptions } from '../components/Options';
import posthog from 'posthog-js'
import AthleteLink from '../components/AthleteLink';


const FilterSelect = ({ handleChange, label, values, options, optionsState }) => (
  <Select
    wrapperClassName="mt-0 my-1 px-2 border-0"
    hideBorder={true}
    hideLabel={true}
    label={label}
    values={values}
    value={optionsState}
    options={options}
    onChange={({ target : { value }}) => 
      handleChange(label, value)
    }
  />
)

export class Home extends Component {

    state = {
      isMobile: false,
      loading: true,
      unfilteredLifts: [],
      weight_classFilter: undefined,
      genderFilter: undefined,
      nationalityFilter: undefined,
      lifts: [],
      sortBy: 'total'
    }
  
    componentDidMount = () => {
      posthog.capture('PageView', { route: '/' })

      const screenWidth = window.innerWidth;
      // const screenHeight = window.innerHeight;
  
      if (screenWidth < 500){
        // console.log(screenWidth);
        this.setState({ 
          isMobile: true
        })
      }
      // fetch lifts on page load
      this.getLiftsNew();

      // set filters
      const url = new URL(window.location);
      const gender = url.searchParams.get('gender');
      if(gender !== null && gender !== undefined) {
        this.setState({ 'genderFilter': gender });
      }
      const nationality = url.searchParams.get('nationality');
      if(nationality !== null && nationality !== undefined) {
        this.setState({ 'nationalityFilter': nationality });
      }
      const weight_class = url.searchParams.get('weight_class');
      if(weight_class !== null && weight_class !== undefined) {
        this.setState({ 'weight_classFilter': weight_class });
      }
    }

    handleFilter = (newFilter, value) => {
      // set the filter value in state then update lifts
      this.setState({ [newFilter + 'Filter']: value }, () => {
        this.updateUrlParam(newFilter, value);
        }
      )
    }

    handleSort = (_, sortBy) => {
      if(sortBy !== this.state.sortBy) {
        this.updateUrlParam('sortBy', sortBy);
        this.setState({ loading: true });

        this.getLifts(sortBy).then(lifts => {
          this.setState({
            lifts: lifts,
            loading: false,
            sortBy
          });
        });
      }
    }

    getLiftsNew = (order_by) => {
        const url = new URL(window.location);
        let params = url.searchParams.toString();
        if (order_by !== null && order_by !== undefined){
            params += `order_by=${order_by}`
        }
        return get(`api/lifts?${params}`).then((res) => {
            // console.log(res.data.length)
            this.setState({
                lifts: res.data,
                loading: false,
                unfilteredLifts: res.data
            })
            return res.data;
        }).catch((err) => {
          // console.log(err);
          this.setState({
            loading: false,
            error: true
          });
        });
    }

    updateUrlParam = (key, value) => {
      const url = new URL(window.location);
      if(value === 'All') {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value);
      }
      window.history.pushState(null, '', url.toString());
      this.getLiftsNew();
    }

    loadMoreLifts = () => {
        const { lifts } = this.state;
        const page = (lifts.length / 100) + 1;

        const url = new URL(window.location);
        let params = url.searchParams.toString();
        if(params === ''){
            params = `page=${page}`
        } else {
            params += `&page=${page}`
        }

        get(`api/lifts?${params}`).then((res) => {
            if(res.data.length > 1) {
                this.setState({
                    lifts: this.state.lifts.concat(res.data),
                    loading: false
                });
            }
        });
    }

    render() {
      const {
        error,
        genderFilter,
        isMobile,
        loading,
        lifts,
        nationalityFilter,
        weight_classFilter
      } = this.state;

      const filterWidth = "w-1/2 md:w-3/12 lg:w-1/12";

      return (
              <div
                style={{
                  backgroundColor: lifts.length % 2 === 0 ? '#F9FAFB' : '#F3F4F6',
                  height: '98vh',
                  overflowY: 'scroll'
                }}
              >
                <div className="flex flex-wrap md:flex-nowrap bg-black md:bg-red-500 p">
                  <div className={filterWidth}>
                    <FilterSelect
                      label={'weight_class'}
                      options={<WeightClassOptions/>}
                      optionState={weight_classFilter}
                      handleChange={this.handleFilter}
                    />
                  </div>
                  <div className={filterWidth}>
                    <FilterSelect
                      label={'gender'}
                      options={<GenderOptions/>}
                      optionsState={genderFilter}
                      handleChange={this.handleFilter}
                    />
                  </div>
                  <div className={filterWidth}>
                    <FilterSelect
                      label={'nationality'}
                      options={<NationalityFilterOptions/>}
                      optionsState={nationalityFilter}
                      handleChange={this.handleFilter}
                    />
                  </div>
                  <div className={filterWidth}>
                    <FilterSelect
                      label={'lift'}
                      options={<LiftOptions/>}
                      handleChange={this.handleSort}
                    />
                  </div>
                </div>
                <>
                {error ?
                    <div className='w-full text-center'>
                      Failed to load lifts
                  </div>
                  :
                  <span></span>
                }
                </>
                <>
                  {loading ?
                    <div className='w-full text-center'>
                      Loading...
                  </div>
                  :
                  <>
                  {isMobile
                  ?
                  <>
                      <div className="flex py-2 font-semibold" style={{ backgroundColor: "#fff"}}>
                          <div className="w-24 pl-2">#</div>
                          <div className="w-96">Name</div>
                          <div className="w-36">Cls.</div>
                          <div className="w-48 pl-2">Log</div>
                          <div className="w-48">DL</div>
                          {/* <div className="w-48">Gender</div> */}
                      </div>
                      {lifts.map(({ uid, name, weight_class, log, deadlift, gender }, idx) => (
                      <div
                          className="py-2 flex w-full"
                          style={{ backgroundColor: idx % 2 !== 0 ? '#F9FAFB' : '#F3F4F6' }}
                          key={uid+idx}
                      >
                          <div className="w-24 pl-2">{idx+1}</div>
                          <div className="w-96"><AthleteLink name={name} uid={uid}/></div>
                          <div className="w-36">{weight_class}</div>
                          <div className="w-48 pl-2">{log}</div>
                          <div className="w-48">{deadlift}</div>
                          {/* <div className="w-48">{gender === 'Male' ? 'M' : 'F'}</div> */}
                      </div>
                  ))}
                  </>
                  :
                  <>
                      <div id="lifts" className="flex py-2 font-semibold" style={{ backgroundColor: "#fff"}}>
                        <div className="w-24 pl-2">#</div>
                        <div className="w-80">Name</div>
                        {/* <div className="w-48">Date</div> */}
                        <div className="w-80">Country</div>
                        <div className="w-48">Class</div>
                        {/* <div className="w-104">Competition</div> */}
                        <div className="w-48 pl-2">Log</div>
                        <div className="w-48">Deadlift</div>
                        <div className="w-48">Total</div>
                        {/* <div className="w-48">Gender</div>
                        <div className="w-36">Age</div>
                        <div className="w-24">Verified</div>  */}
                      </div>
                      {lifts.map(({ uid, competition, name, weight_class, nationality, competition_date, log, deadlift, total, gender, age }, idx) => (
                      <div
                          className="py-2 flex w-full"
                          style={{ backgroundColor: idx % 2 !== 0 ? '#F9FAFB' : '#F3F4F6' }}
                          key={uid+idx}
                      >
                          <div className="w-24 pl-2">{idx + 1}</div>
                          <div className="w-80">
                            <AthleteLink name={name} uid={uid}/>
                          </div>
                          {/* <div className="w-48">{competition_date}</div> */}
                          <div className="w-80">{nationality}</div>
                          <div className="w-48">{weight_class}</div>
                          {/* <div className="w-104">{competition}</div> */}
                          <div className="w-48 pl-2">{log}</div>
                          <div className="w-48">{deadlift}</div>
                          <div className="w-48">{total}</div>

                          {/*
                          <div className="w-48"><Example idx={idx}/></div>
                          */}
                          {/*<div className="w-48">{gender}</div>
                          <div className="w-36">{age}</div>*/}
                      </div>
                      ))}
                  </>
                  }
                  </>
                  }
                </>
                <div className="w-full flex bg-white">
                    <div className="w-1/3">

                    </div>
                    <div className="w-1/3 font-semibold text-center">
                        {
                        lifts.length >= 0 ?
                            <button onClick={() => {this.loadMoreLifts()}} className="bg-transparent hover:bg-black-500 text-black-700 font-semibold my-2 py-2 px-4 border border-black-500 hover:border-transparent rounded">
                                Load more
                            </button>
                        :
                            <div></div>
                        }
                    </div>
                    <div className="w-1/3">
                    </div>
                </div>
                <div className="w-full flex bg-white">
                    <a
                      className="mx-auto text-center bg-white hover:text-blue-400 text-blue-300 font-semibold my-2 py-2 px-4"
                      href="https://docs.google.com/forms/d/1kwRoqQDwOepLo1tF2VZZft6TAAqAlfaPULGk2lhnAjs/prefill"
                      target="_blank"
                      rel="noreferrer"
                    >
                        Leave Feedback
                    </a>
                </div>

              </div>
              )
    }
  }
  