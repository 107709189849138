import axios from 'axios';

export const get = (path) => {
    return axios.get(process.env.REACT_APP_API_URL + path).then(res => {
        console.log(Object.keys(res.data))
        return res.data
    })
}

export const post = (path, data) => {
    return axios.post(process.env.REACT_APP_API_URL + path, data).then(res => {
        return res.data
    })
}
