import React, { useRef } from 'react';

export const Input = (props) => {
    const {
        id,
        wrapperClassName = '',
        placeholder = '',
        label = '',
        type = 'text',
        error = false,
        errorText = '',
        required = false,
        ...rest
    } = props;
  
    const inputRef = useRef();
  
    return (
        <div className={'md:mt-2' + wrapperClassName}>
            <div
                className={`border transition duration-150 ease-in-out ${
                    error
                        ? 'focus-within:border-red border-red'
                        : 'focus-within:border-primary border-gray-gray4'
                }`}
                onClick={() => inputRef.current.focus()}
            >
                <label
                    htmlFor={id}
                    className='text-xs text-primary font-light placeholder-gray-gray4 px-2 pt-1.5'
                >
                    {label} {required && <span className='text-red'>*</span>}
                </label>
                <input
                    ref={inputRef}
                    type={type}
                    className='w-full px-2 py-1.5 text-primary outline-none text-base font-light rounded-md pl-3'
                    id={id}
                    placeholder={placeholder}
                    {...rest}
                />
            </div>
            {errorText && (
                <p className='text-xs pl-2  text-red mb-4'>{errorText}</p>
            )}
        </div>
    );
  }

  export const Select = (props) => {
    const {
        id,
        wrapperClassName = '',
        // placeholder = '',
        label = '',
        // type = 'text',
        error = false,
        errorText = '',
        required = false,
        values = [],
        hideBorder,
        hideLabel,
        options,
        ...rest
    } = props;

    return (
        <div className={'md:mt-2' + wrapperClassName}>
            <div
                className={
                    `
                    ${hideBorder
                        ? ' '
                        : 'border transition duration-150 ease-in-out '
                    }
                    ${error
                        ? 'focus-within:border-red border-red'
                        : 'focus-within:border-primary border-gray-gray4'
                }`}
                // onClick={() => inputRef.current.focus()}
            >
                {hideLabel 
                ? <></>
                : <label
                    htmlFor={id}
                    className='text-xs text-primary font-light placeholder-gray-gray4 px-2 pt-1.5'
                >
                    {label} {required && <span className='text-red'>*</span>}
                </label>
                }
                <select
                  className='w-full px-2 py-1.5 text-primary outline-none text-base font-light rounded-md bg-white'
                  {...rest}
                >
                    {options !== null && options !== undefined
                    ?
                    <>
                        {options}
                    </>
                    :
                    <>
                        {values.map(value => (
                            <option value={value}>{value}</option>
                        ))}
                    </>
                    }
                </select>
            </div>
            {errorText && (
                <p className='text-xs pl-2  text-red mb-4'>{errorText}</p>
            )}
        </div>
    );
}
