import React, { Component } from 'react';


export class Modal extends Component {

    render() {

        const { child, display } = this.props;

      return (
        <>
            {display &&
            <>
            <div
                id="modal-background"
                style={{ 
                    backgroundColor: "#F4F6F6",
                    opacity: 0.9,
                    overflowY: 'hidden',
                    zIndex: 9,
                    position: "absolute",
                    top: 0
                }}
                className="absolute h-full w-full"
                onClick={() => this.props.onClose()}
            />
            <div
                className="fixed mx-1 mb"
                style={{
                    top: 2,
                    zIndex: 10,
                    width: '96%'
                }}
            >
                {/* <div className="w-11/12 md:w-1/2 mx-auto p-1 text-right">
                <svg 
                    height="30"
                    width="30"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => this.props.onClose()}
                >
                    <circle cx="15" cy="15" r="15" stroke="black" stroke-width="0" fill="white" />
                    <text x="50%" y="50%" text-anchor="middle" stroke="black" stroke-width="2px" dy=".3em">X</text>
                </svg>
                </div>  */}
                <div
                    className="w-11/12 md:w-1/2 mx-auto my-2 p-1 bg-white"
                    style={{
                        background: 'white',
                        borderRadius: '0.5rem'
                    }}
                >
                    {child}
                    <div className="text-center -mt-2">
                        <button
                            className="bg-white px-4 text-black"
                            onClick={() => this.props.onClose()}
                        >
                            Close
                        </button>
                    </div>
                </div>
               </div>
            </>
            }

        </>
      )
    }
  
  }