import React from 'react';
import nationaliies from '../data/nationalities.json'

export const GenderOptions = () => (
    <>
        <option value={'All'}>All Genders</option>
        <option value={'Male'}>Male</option>
        <option value={'Female'}>Female</option>
    </>
)

export const LiftOptions = () => (
    <>
        <option value={'total'}>Total</option>
        <option value={'log'}>Log</option>
        <option value={'deadlift'}>Deadlift</option>
    </>
)

export const WeightClassOptions = () => (
    <>
        <option value={'All'}>All Weight Classes</option>
        <optgroup label="Men">
            <option value={'Men Open'}>Open</option>
            <option value={'Men U105'}>U105</option>
            <option value={'Men U90'}>U90</option>
            <option value={'Men U80'}>U80</option>
            <option value={'Men U70'}>U70</option>
        </optgroup>
        <optgroup label="Women">
            <option value={'Women Open'}>Open</option>
            <option value={'Women U82'}>U82</option>
            <option value={'Women U73'}>U73</option>
            <option value={'Women U64'}>U64</option>
            <option value={'Women U57'}>U57</option>
        </optgroup>
    </>
)

export const SelectGenderOptions = () => (
    <>
        <option value={'Male'}>Male</option>
        <option value={'Female'}>Female</option>
    </>
)

export const NationalityOptions = () => {
    return (
        <>
            {/* {['Irish', 'English', 'American'].map(nation =>
                <option key={nation} value={nation}>{nation}</option>
            )} */}
            {/* <optgroup/> */}
            {nationaliies.map(nation =>
                <option key={nation} value={nation}>{nation}</option>
            )}
        </>
    )
}

export const NationalityFilterOptions = () => {
    return (
        <>
            <option value={'All'}>All Nationalities</option>
            {nationaliies.map(nation =>
                <option key={nation} value={nation}>{nation}</option>
            )}
        </>
    )
}

// TODO deduplicate this
export const SelectWeightClassOptions = () => (
    <>
        <optgroup label="Men">
            <option value={'Men Open'}>Open</option>
            <option value={'Men U105'}>U105</option>
            <option value={'Men U90'}>U90</option>
            <option value={'Men U80'}>U80</option>
            <option value={'Men U70'}>U70</option>
        </optgroup>
        <optgroup label="Women">
            <option value={'Women Open'}>Open</option>
            <option value={'Women U82'}>U82</option>
            <option value={'Women U73'}>U73</option>
            <option value={'Women U64'}>U64</option>
            <option value={'Women U57'}>U57</option>
        </optgroup>
    </>
)

